import React from "react";
import Icon, { IconProps } from "../Icon";

export default function DaydreamLogo({
  viewBox = "0 0 48 48",
  width = 48,
  height = 48,
  fill = "#FF50BC",
  ...iconProps
}: IconProps) {
  return (
    <Icon height={height} width={width} {...iconProps} viewBox={viewBox}>
      <rect width="48" height="48" fill="#fff" rx="4"></rect>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M40.221 11.029a.812.812 0 01.145 1.059l-11.98 20.451a.886.886 0 01-1.345.144l-4.87-7.624L9.885 37.026a.891.891 0 01-1.107-.055.813.813 0 01-.145-1.059l12.9-17.247a.886.886 0 011.345-.144l4.48 5.373 11.755-12.92a.891.891 0 011.107.055z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
