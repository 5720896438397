import React from "react";
import Icon, { IconProps } from "./Icon";

export default function EyeClosed({
  viewBox = "0 0 32 32",
  fill = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox} size={32}>
      <path
        fill={fill}
        d="M21.745 14h1.185a7.888 7.888 0 01-2.063 2.317l1.317 2.281-.866.5-1.284-2.224a8.118 8.118 0 01-3.534 1.109V20.5h-1v-2.517a8.139 8.139 0 01-3.531-1.113l-1.287 2.228-.866-.5 1.32-2.285A7.87 7.87 0 019.07 14h1.185A6.992 6.992 0 0016 17a6.992 6.992 0 005.745-3z"
      ></path>
    </Icon>
  );
}
