import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Causal({ size = 24, ...iconProps }: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox="0 0 225 211">
      <g clipPath="url(#clip0_310_10219)">
        <path
          fill="#C3D9F3"
          fillRule="evenodd"
          d="M112.539 211c62.156 0 112.544-47.233 112.544-105.5C225.083 47.234 174.695 0 112.539 0S-.004 47.234-.004 105.5c0 58.267 50.387 105.5 112.543 105.5zm0-39.439c38.92 0 70.471-29.576 70.471-66.061 0-36.484-31.551-66.06-70.471-66.06s-70.47 29.576-70.47 66.06c0 36.485 31.55 66.061 70.47 66.061z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_310_10219">
          <path fill="#fff" d="M0 0H225V211H0z"></path>
        </clipPath>
      </defs>
    </Icon>
  );
}
