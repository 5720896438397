import React from "react";
import Icon, { IconProps } from "../Icon";

export default function Sturppy({ viewBox = "0 0 24 24", size = 24, ...iconProps }: IconProps) {
  return (
    <Icon size={size} {...iconProps} viewBox={viewBox}>
      <rect width={24} height={24} fill="gold" rx={4} />
      <path
        fill="#fff"
        d="M13.246 9.946c2.6 1.56 4.926 2.427 4.926 5.286 0 3.825-2.722 5.955-6.512 5.955-2.327 0-4.815-.965-6.252-3.218l2.897-2.55c.842 1.436 2.204 2.14 3.38 2.14 1.424 0 2.254-.792 2.254-1.708 0-.842-.756-1.2-2.451-2.19-2.104-1.238-4.432-2.402-4.432-5.36 0-3.43 2.749-5.596 6.154-5.596 2.104 0 4.283.928 5.522 2.76L15.86 7.904c-.978-1.337-1.969-1.597-2.81-1.597-1.052 0-1.784.67-1.758 1.534.025.719.495 1.238 1.955 2.105h-.001Z"
      />
    </Icon>
  );
}
