import React from "react";
import Icon, { IconProps } from "./Icon";

export default function EyeOpen({
  viewBox = "0 0 32 32",
  fill = "currentColor",
  ...iconProps
}: IconProps) {
  return (
    <Icon {...iconProps} viewBox={viewBox} size={32}>
      <path fill={fill} d="M16 18a2 2 0 100-4 2 2 0 000 4z"></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M16 12a7.499 7.499 0 016.635 4A7.499 7.499 0 0116 20a7.499 7.499 0 01-6.635-4A7.499 7.499 0 0116 12zm0 7a6.495 6.495 0 01-5.478-3A6.495 6.495 0 0116 13c2.3 0 4.322 1.194 5.479 3A6.495 6.495 0 0116 19z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
}
